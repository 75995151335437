
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";

import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import json from "@/service/DataStore.json";


export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { UpdateUser } = useRegister();
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    const store = inject("store");
    const router = inject("router");

    const items = ref(json);

    const data_register = store.getters.getRegister;

    const register = Yup.object().shape({
      numberhome: Yup.string()
        .required("กรุณากรอก บ้านเลขที่")
        .label("บ้านเลขที่"),
      moo: Yup.string().label("หมู่").nullable(),
      alleyway: Yup.string().label("ตรอก/ซอย").nullable(),
      road: Yup.string().label("ถนน").nullable(),
      province: Yup.string().required("กรุณาเลือก จังหวัด").label("จังหวัด"),
      amphoe: Yup.string().required("กรุณาเลือก อำเภอ/เขต").label("อำเภอ/เขต"),
      district: Yup.string()
        .required("กรุณาเลือก ตำบล/แขวง")
        .label("ตำบล/แขวง"),
      zipcode: Yup.string()
        .required("กรุณากรอก รหัสไปรษณีย์")
        .label("รหัสไปรษณีย์"),
    });

    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter(
        (item) => item.province === data_register.province
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter(
        (item) => item.amphoe === data_register.amphoe
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    function setZipcode(val) {
      let result = val;

      let data = items.value.filter(
        (item) =>
          item.district === result && item.amphoe === data_register.amphoe
      );
      data_register.zipcode = data[0].zipcode;
    }

    const back = () => {
      router.push({ name: "Edit" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      store.dispatch(SET_REGISTER, values);

      Sconfirm("ยืนยัน การแก้ไขข้อมูล", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await UpdateUser(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("แก้ไขข้อมูลสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
      uniqueProvince,
      uniqueAmphoe,
      uniqueDistrict,
      setZipcode,
    };
  },
});
